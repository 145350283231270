
import { defineComponent } from "vue";
import { NotificationContent } from "@/types/models";
import { notificationGet } from "@/api/index";
import axios from "axios";
import ContentCard from "@/components/Notification/ContentCard.vue";
// import CategoryCard from "@/components/Notification/CategoryCard.vue";
// import AltCategoryCard from "@/components/Notification/AltCategoryCard.vue";
import Template11 from "@/views/templates/Template11.vue";

var serverUrl = process.env.VUE_APP_SERVER;
var loginToken = `Bearer ${localStorage.getItem("token")}` as string;

const categoryMap = [
  "全部",
  "開課通知",
  "繳費通知",
  "活動通知",
  "教材傳送",
  "其他通知",
] as Array<string>;

export default defineComponent({
  name: "Message",
  components: {
    Template11,
    ContentCard,
    // CategoryCard,
    // AltCategoryCard,
  },
  data() {
    return {
      activeCategory: "0",
      // 進入頁面後 API 取得訊息資料會先推入 cardContent ，然後再賦值給 displayContent
      // 但兩個動作看起來是連續的，中間未經過任何處理，目前不理解為何要透過兩個變量 2023.03.23 by yi
      cardContent: [] as Array<any>,
      // 所有通知的資料內容，傳入 ContentCard.vue 內透過 v-for 產生顯示
      displayContent: [] as Array<any>,
      // 原先的變量，但初始值宣告成字串會出現警報提示因此改為空陣列
      // displayContent: "no result" as Array<any> | string,
      index: 0,
      targetNum: 0,
      addEvent: false,
      event: null as any,
    };
  },
  computed: {
    categoryName(): string {
      return categoryMap[parseInt(this.activeCategory)];
    },
  },
  methods: {
    updateTitle(updatedCategory: string) {
      // console.log(`Updating: ${updatedCategory}`);
      this.activeCategory = updatedCategory;
      // API 撈出分類後資料
      this.displayContent = [];
      // 全部
      if (this.activeCategory == "0") {
        this.displayContent = this.cardContent;
      }
      // 開課通知
      else if (this.activeCategory == "1") {
        this.cardContent.forEach((ele) => {
          if (ele.type == "0") {
            this.displayContent = [];
            this.displayContent.push(ele);
          }
        });
      }
      // 繳費通知
      else if (this.activeCategory == "2") {
        this.cardContent.forEach((ele) => {
          if (ele.type == "1" || ele.type == "2") {
            this.displayContent = [];
            this.displayContent.push(ele);
          }
        });
      }
      // 活動通知；教材傳送；其他通知
      else {
        this.cardContent.forEach((ele) => {
          if (ele.type == this.activeCategory) {
            this.displayContent = [];
            this.displayContent.push(ele);
          }
        });
      }
    },
    async dataGet() {
      let data = await notificationGet();
      if (data.status === 200) {
        this.cardContent = data.data.data.data.list;
        this.dataRender();
      }
    },
    dataRender() {
      // 每一次渲染資料只取五筆
      let step = 5;
      this.targetNum = this.index + step;
      // 取得資料後直接渲染五筆在畫面上，多的利用下方的事件註冊才渲染
      let data = this.cardContent.slice(this.index, this.targetNum);
      data.forEach((item) => {
        this.displayContent.push(item);
      });
      // 註冊資料渲染事件
      if (this.index <= this.cardContent.length && this.addEvent === false) {
        this.event = setInterval(() => {
          this.dataRender();
        }, 200);
      }
      // 資料渲染完畢就刪除事件
      if (this.index > this.cardContent.length) {
        clearInterval(this.event);
      }
      this.index = this.targetNum;
      this.targetNum = this.targetNum + step;
      this.addEvent = true;
    },
  },
  created() {
    this.dataGet();
  },
});
