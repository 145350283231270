
import { defineComponent, PropType } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface Notification {
  id: number;
  title: string;
  content: NotificationContent;
  setDate: number;
  file: string | null;
  type: string;
  postDateStart: string;
  postDateEnd: string;
  mReadID: string;
  isRead: number;
  date: string;
}

interface NotificationContent {
  course: string;
  date: string | number;
  period: Array<number>;
  term: number;
  price: number;
  periods: string;
  message: string;
  official: boolean;
  postId: number;
  cnName: string;
  foreignName: string;
  content: string;
  teacherId: number;
  teacher: string;
}

export default defineComponent({
  name: "NotificationCard",
  mixins: [mixins],
  data() {
    return {
      user: "",
      title:
        "a - apple b - bananac - catd - doge - elephantf - frogg - guitarh - horsei - igloo - jellyfishk - kangaroo - liom - monkeyn - narwhalo - octopusp - pineappleq - quailr - rabbits - sunflowert - turtleu - umbrellav - volcanow - watermelonx - x-rayy - yellowz - zebra",
    };
  },
  methods: {
    // 開啟新的一頁 - 下載教材
    openFile(filePath: string) {
      window.open(filePath);
    },
    parseJson(obj: string) {
      return JSON.parse(obj);
    },
    sliceDate(date: string) {
      return date.slice(0, 11);
      // return date.slice(5, 11);
    },
    sliceDate2(date: string) {
      // return [date.slice(0, 5), date.slice(5, 11)];
      return date.slice(5, 11);
    },
    readMessage(id: number) {
      // console.log(id);
      // 打後端 API
      axios
        .patch(
          `${serverUrl}ann/${id}/read`,
          {},
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log("Done");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 老師名稱組合，由於在 template 內組合變數會混亂，因此直接接收整個資料內容，進而判斷回傳組合好的名稱
    // 例如，是否有外文名字
    nameProcess(obj: Notification) {
      return obj.content.foreignName;
    },
    // 上課時段計算
    classDruingCal(array: Array<number>) {
      // 轉換成 JavaScript Date 物件
      const timestampArray = [
        unixTimestampToJsDate(array[0]),
        unixTimestampToJsDate(array[1]),
      ];
      let timeResultArray = [];
      for (let i = 0; i < 2; i++) {
        let hour = "";
        let minute = "";
        let result = "";
        if (timestampArray[i].getHours.toString().length === 1) {
          hour = "0" + timestampArray[i].getHours().toString();
        } else {
          hour = timestampArray[i].getHours().toString();
        }
        if (timestampArray[i].getMinutes().toString().length === 1) {
          minute = "0" + timestampArray[i].getMinutes().toString();
        } else {
          minute = timestampArray[i].getMinutes().toString();
        }
        result = `${hour}:${minute}`;
        timeResultArray.push(result);
      }
      return `${timeResultArray[0]} ~ ${timeResultArray[1]}`;

      function unixTimestampToJsDate(unixTimestamp: number): Date {
        return new Date(unixTimestamp * 1000);
      }
    },
    // 前往教師頁面
    goToTeacherIntroduction(obj: { teacherId: number }) {
      let url = `teachers/teachersintroduction?id=${obj.teacherId}`;
      window.location.href = url;
    },
    // 處理發文中連結
    linkify(text: string) {
      // eslint-disable-next-line
      var urlRegex =
        /* eslint-disable no-useless-escape */
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return (
          '<a class="text-primary-default" target="_blank" href="' +
          url +
          '">' +
          url +
          "</a>"
        );
      });
    },
  },
  props: {
    notification: {
      type: Array as PropType<Array<Notification>>,
      required: true,
    },
  },
  // props: ["notification"],
  mounted() {
    // 取得使用者資訊
    axios
      .get(`${serverUrl}personal`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.user = res.data.data.user.name;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
